
const state = {
	// userList: JSON.parse(window.localStorage.getItem('user')) || {}
	userList: {},
	ocr: {}
}

const mutations = {
	SET_userList: (state, userList) => {
		state.userList = userList
		// window.localStorage.setItem('user', JSON.stringify(userList))
	},
	SET_OCR: (state, userList) => {
		state.ocr = userList
		// window.localStorage.setItem('user', JSON.stringify(userList))
	},
}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
