import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	// set './app.js' => 'app'
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	const value = modulesFiles(modulePath)
	modules[moduleName] = value.default
	return modules
}, {})

const store = new Vuex.Store({
  state: {
    flutter_baseInfo: {},
    flutter_cId:'',
    ifscCode:'',
  },
  mutations: {
    getFromApp(state,n){
      state.flutter_baseInfo=n
    },
    getCustomerId(state,n){
      state.flutter_cId=n
    },
    getIfscCode(state,n){
      state.ifscCode=n
    }
  },
	modules,
	getters
})

export default store
