
export function customerGender(status) {
	switch (status * 1) {
		case 0:
			return 'unknown'
		case 10:
			return 'Male'
		case 20:
			return 'Woman'
		case 30:
			return 'Transgender'
		default:
			return ''
	}
}

export function orderStatus(status) {
	switch (status * 1) {
		case 10:
			return 'Under approval'
		case 20:
			return 'Audit Reject'
		case 30:
			return 'To Be Repaid'
		case 40:
			return 'Overdued'
		case 50:
			return 'Completed'
		case 60:
			return 'other'
		case 70:
			return 'Order Close'
		default:
			return ''
	}
}
