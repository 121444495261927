
const state = {
	show: false,
	productList: [],
	time: '',
	url: [],

}

const mutations = {
	SET_show: (state, userList) => {
		state.show = userList
	},
	SET_productList: (state, productList) => {
		state.productList = productList
	},
	SET_time: (state, time) => {
		state.time = time
	},
	SET_url: (state, url) => {
		state.url = url
	},

}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
